import React from 'react'

import { Layout } from 'src/components/Layout'

const NotFoundPage = () => (
  <Layout>
    {/*<Header pageTitle="Page not found" />*/}
    {/*
    <CopyContainer>
      <Image src={LogoWindow} />
      <Copy>Sorry, we couldn't find that page.</Copy>
      <ButtonAlt primary to="/">
        Back to home
      </ButtonAlt>
    </CopyContainer>
    */}
  </Layout>
)

export default NotFoundPage
